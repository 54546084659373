import classNames from 'classnames'
import LayoutContainer from './LayoutNext/LayoutContainer'
import bottomBg from './PricingSection/bg_bottom.svg'
import LinkButton from './LayoutNext/LinkButton'
import SiteUrls from '../modules/SiteUrls'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

const partsHubPlans = [
  {
    isMostPopular: false,
    showFee: true,
    name: 'Sourcing',
    desc: 'Assists individuals searching for hard-to-find parts.',
    highlights: [
      'Unlimited requests to the Gearflow Supplier Network',
      'Easy quote comparisons',
      'Instant messaging with your vendors',
      'Automated notifications to keep you informed',
    ],
    price: '$0/mo',
    ctaText: 'Sign Up Now',
    ctaHref: '/signup',
  },
  {
    isMostPopular: true,
    showFee: false,
    name: 'Pro',
    desc: 'Empowers heavy equipment fleet teams to increase uptime and cost efficiencies.',
    highlights: [
      'Everything included in the Sourcing Plan',
      'One place for all your vendors',
      'Get parts requests straight from your technicians with Parts Hub Pro app',
      'End-to-end collaboration tools across your team’s workflow',
      'Real-time cost, process, team, and vendor insights',
    ],
    pretext: 'Starts at',
    posttext: 'Includes 5 licenses',
    price: '$2,850/mo',
    ctaText: 'Book a Discovery Call',
    ctaHref: SiteUrls.BUYER_SCHEDULE_DEMO_URL,
    priceLabel: '',
  },
  {
    isMostPopular: false,
    name: 'Enterprise',
    showFee: false,
    desc: 'Get dedicated support and solutions customized for your organization.',
    highlights: [
      'Everything included in the Pro Plan',
      'Advanced reporting insights',
      'Integrations with internal systems',
      'Specialized service from our expert team',
    ],
    price: 'Custom',
    ctaText: 'Contact Us',
    ctaHref: '/contact-us',
  },
]

const salesHubPlans = [
  {
    isMostPopular: false,
    name: 'Basic',
    desc: 'Win new business from Gearflow users looking for alternative options.',
    highlights: [
      'Win new business from the Gearflow Supplier Network',
      'Automated quoting tools',
      'Electronic payments & payouts',
      'Sales insights to measure performance',
    ],
    price: '$0 per user',
    posttext: (
      <>
        +12.65% transaction fee
        <br className="hidden sm:block" />
        on new business
      </>
    ),
    ctaText: 'Book a Discovery Call',
    ctaHref: SiteUrls.DEALER_SCHEDULE_DEMO_URL,
    showFee: false,
  },
  {
    isMostPopular: true,
    name: 'Pro',
    desc: 'Close more parts business across all of your customers.',
    highlights: [
      'Everything included in the Basic Plan',
      'Manage orders across all new & existing customers',
      'Invoice your customers on their current account & pricing',
      'Deploy customer facing mobile app for repeat customers',
      'Real time support',
    ],
    pretext: 'Starts at',
    price: '$100/month per user',
    ctaText: 'Book a Discovery Call',
    ctaHref: SiteUrls.DEALER_SCHEDULE_DEMO_URL,
    priceLabel: '',
    showFee: false,
  },
  {
    isMostPopular: false,
    name: 'Enterprise',
    desc: 'Get dedicated support and solutions customized for your organization.',
    highlights: [
      'Everything included in the Pro Plan',
      'Advanced reporting insights',
      'Integrations with internal systems',
      'Specialized service from our expert team',
    ],
    pretext: 'Starts at',
    price: '$1,950/month',
    posttext: (
      <>
        Includes 5 licenses <br className="hidden sm:block" />
        $125/mo/add&apos;l user
      </>
    ),
    ctaText: 'Book a Discovery Call',
    ctaHref: SiteUrls.DEALER_SCHEDULE_DEMO_URL,
    showFee: false,
  },
]

type Plan = {
  pretext?: string
  posttext?: ReactNode
  showFee: boolean
  isMostPopular?: boolean
  name: string
  desc: string
  highlights: string[]
  price: string
  priceLabel?: string
  ctaText: string
  ctaHref: string
}

const CheckIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className="flex-shrink-0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0484 6.35159C20.2734 6.57662 20.3997 6.88179 20.3997 7.19999C20.3997 7.51818 20.2734 7.82335 20.0484 8.04839L10.4484 17.6484C10.2234 17.8734 9.9182 17.9997 9.6 17.9997C9.2818 17.9997 8.97663 17.8734 8.7516 17.6484L3.9516 12.8484C3.73301 12.6221 3.61206 12.3189 3.61479 12.0043C3.61753 11.6897 3.74373 11.3887 3.96622 11.1662C4.18871 10.9437 4.48968 10.8175 4.80432 10.8148C5.11895 10.812 5.42208 10.933 5.6484 11.1516L9.6 15.1032L18.3516 6.35159C18.5766 6.12662 18.8818 6.00024 19.2 6.00024C19.5182 6.00024 19.8234 6.12662 20.0484 6.35159Z"
      fill="#111827"
    />
  </svg>
)

const Plan = ({
  pretext,
  posttext,
  showFee,
  isMostPopular = false,
  name,
  desc,
  highlights,
  price,
  priceLabel,
  ctaText,
  ctaHref,
}: Plan) => (
  <motion.section
    className={classNames(
      'rounded-xl xl:rounded-[1.25rem] shadow-lg flex flex-col flex-shrink-0 ',
      isMostPopular
        ? 'xl:w-[28.125rem] xl:h-[49.5rem] bg-blue-500 p-[0.3125rem]'
        : 'xl:w-[22.9375rem] xl:h-[40.625rem]'
    )}
    variants={{
      visible: {
        opacity: 1,
        translateX: 0,
        transition: { type: 'spring', stiffness: 300, damping: 40 },
      },
      hidden: { opacity: 0.0, translateX: -100, transition: { duration: 0.4 } },
    }}
  >
    {isMostPopular && (
      <p className="text-center text-white font-medium text-xl pt-[0.125rem] pb-[0.4375rem]">
        Most Popular
      </p>
    )}
    <div className="flex-grow flex flex-col p-4 sm:p-8 bg-white rounded-xl xl:rounded-[1.25rem]">
      <hgroup className="space-y-2 sm:space-y-4">
        <h3 className="text-3xl sm:text-5xl font-medium leading-[2.5rem] sm:leading-[3.25rem]">
          {name}
        </h3>
        <p className="text-sm sm:text-base">{desc}</p>
      </hgroup>
      <ul className="mt-4 sm:mt-8 space-y-2 sm:space-y-6 flex-grow">
        {highlights.map((text) => (
          <li key={text} className="flex gap-x-2 sm:gap-x-4 text-sm sm:text-base">
            <CheckIcon /> {text}
          </li>
        ))}
      </ul>
      {pretext && <p className="text-xs sm:text-sm pt-10 xl:pt-0">{pretext}</p>}
      <p
        className={classNames(
          'font-medium text-xl sm:text-3xl',
          pretext ? 'pt-0' : 'pt-10 xl:pt-0'
        )}
      >
        {price}{' '}
        {priceLabel && <span className="text-sm md:text-base font-normal">{priceLabel}</span>}
      </p>
      {showFee && <p className="text-sm sm:text-base mt-1 sm:mt-2">+3% transaction fee</p>}
      {posttext && <p className="text-sm sm:text-base mt-1 sm:mt-2">{posttext}</p>}
      <LinkButton href={ctaHref} className="w-full mt-4 sm:mt-8">
        {ctaText}
      </LinkButton>
    </div>
  </motion.section>
)

const PricingSection = ({
  title,
  subtitle,
  plans,
  navigation,
  learnMore,
}: {
  title: string
  subtitle: string
  plans: Plan[]
  navigation?: ReactNode
  learnMore?: string
}) => (
  <LayoutContainer
    className="bg-orange-500 how-it-works-pricing-bg"
    id="pricing"
    style={{
      backgroundImage: `url(${bottomBg})`,
    }}
  >
    <section className="py-8 sm:py-16">
      <hgroup className="space-y-4 xl:space-y-8 text-white text-center pb-8">
        <h2 className="font-medium text-3xl xl:text-5xl">{title}</h2>
        {navigation}
        <p className="max-w-[38.625rem] text-base xl:text-lg mx-auto">
          {subtitle}{' '}
          {learnMore && (
            <Link to={learnMore} className="underline">
              Learn more
            </Link>
          )}
        </p>
      </hgroup>
      <motion.div
        className="flex flex-col xl:flex-row xl:items-center xl:justify-center gap-8 py-8"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.6, delayChildren: 0.2, staggerChildren: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
      >
        {plans.map((p) => (
          <Plan key={p.name} {...p} />
        ))}
      </motion.div>
    </section>
  </LayoutContainer>
)

export const PartsHubPricing = ({
  title = 'Work smarter by going Pro',
  navigation,
  showLearnMoreLink,
}: {
  title?: string
  navigation?: ReactNode
  showLearnMoreLink?: boolean
}) => (
  <PricingSection
    title={title}
    subtitle="Get everything your fleet team needs to better manage your parts process with the Parts Hub Pro Plan."
    plans={partsHubPlans}
    navigation={navigation}
    learnMore={showLearnMoreLink ? '/parts-hub' : undefined}
  />
)

export const SalesHubPricing = ({
  title = 'Close more parts business with Sales Hub Pro',
  navigation,
  showLearnMoreLink,
}: {
  title?: string
  navigation?: ReactNode
  showLearnMoreLink?: boolean
}) => (
  <PricingSection
    title={title}
    subtitle="Empower your parts desk with the only software built to connect fleet teams and their vendors."
    plans={salesHubPlans}
    navigation={navigation}
    learnMore={showLearnMoreLink ? '/sales-hub' : undefined}
  />
)

export default { SalesHubPricing, PartsHubPricing }
