import React from 'react'
import { useQueryParams, StringParam } from 'use-query-params'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

import useSignIn from '../hooks/useSignIn'

import Form from '@/gf/components/Form'
import Action from '@/gf/components/Action'
import Layout from '../components/LayoutNext'

import { SignInForm } from '../types'
import LayoutContainer from '../components/LayoutNext/LayoutContainer'

const styles = {
  textInput:
    'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out text-base',
}

const SignIn = () => {
  const [query, setQuery] = useQueryParams({
    return_path: StringParam,
    error: StringParam,
    signup: StringParam,
    dealer: StringParam,
  })
  const [error, setError] = React.useState(query.error)
  const [signupSuccess, _] = React.useState(query.signup)
  const [submitting, setSubmitting] = React.useState(false)
  const signIn = useSignIn()

  const [form, setForm] = React.useState<SignInForm>({
    email: '',
    password: '',
    return_path: query.return_path || '',
  })

  React.useEffect(() => {
    setQuery({ error: undefined, return_path: undefined, signup: undefined })
  }, [])

  const onSubmit = () => {
    setError(undefined)
    setSubmitting(true)

    signIn(form)
      .then((resp) => {
        if (resp.ok) {
          window.location.href = resp.url
        } else {
          resp.json().then((data) => {
            setError(data.error)
          })
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const errorMsg =
    error &&
    {
      invalid_credentials: 'Incorrect email or password.',
      inactive_user: 'Inactive user account.',
      invalid_token: 'Invalid link token',
    }[error]

  return (
    <Layout>
      <LayoutContainer className="bg-gray-50 text-gray-800 py-16">
        <div className={`${signupSuccess ? 'visible' : 'hidden'} pb-8 -mt-4 sm:-mt-8`}>
          <div className="rounded bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-yellow-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-yellow-800">
                  Success! Please check your email for a confirmation link.
                </p>
              </div>
              <div className="ml-auto pl-3" />
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center pb-12 sm:px-6 lg:px-8">
          <div className="mx-auto w-full max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src="/images/android-chrome-512x512.png"
              alt="Logo"
            />
            <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
              Sign in to your account
            </h2>
            <p className="block mt-2 text-center text-sm leading-5 text-gray-600 max-w-full">
              <a
                href="/signup"
                className="mt-2 text-center text-sm leading-5 max-w  text-gearflow hover:text-orange-700 focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                New user? Sign up now.
              </a>
            </p>
          </div>

          <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-8">
            <div className="flex flex-col space-y-2 lg:space-y-6 mt-4 mx-auto w-full max-w-md text-lg text-gray-900">
              {query.dealer ? (
                <p>
                  You&apos;re so close to getting a better way to manage your parts requests and
                  orders! Create a free account in seconds that will make it easier to:{' '}
                </p>
              ) : (
                <p>
                  It&apos;s time to turn your parts process into a competitive advantage! Sign in to
                  access:
                </p>
              )}

              <div className="flex flex-col space-y-2 lg:space-y-4">
                <div className="flex flex-row items-center justify-left">
                  <div className="flex items-center justify-center h-10 min-w-10 rounded-md bg-gearflow text-white">
                    {/* Heroicon name: outline/lightning-bolt */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                  </div>

                  {query.dealer ? (
                    <p className="ml-2 lg:ml-4 text-lg leading-6 text-gray-900">
                      Instantly prioritize inbound parts requests
                    </p>
                  ) : (
                    <p className="ml-2 lg:ml-4 text-lg leading-6 text-gray-900">
                      One place to manage your parts sourcing, from requests to delivery
                    </p>
                  )}
                </div>

                <div className="flex flex-row items-center justify-left">
                  <div className="flex items-center justify-center h-10 min-w-10 rounded-md bg-gearflow text-white">
                    {/* Heroicon name: outline/chat */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                  </div>
                  {query.dealer ? (
                    <p className="ml-2 lg:ml-4 text-lg leading-6 text-gray-900">
                      Centralize messaging with your customers
                    </p>
                  ) : (
                    <p className="ml-2 lg:ml-4 text-lg leading-6 text-gray-900">
                      Instant messaging on all requests and orders to cut calls in half
                    </p>
                  )}
                </div>

                <div className="flex flex-row items-center justify-left">
                  <div className="flex items-center justify-center h-10 min-w-10 rounded-md bg-gearflow text-white">
                    {/* Heroicon name: outline/charge-square-bar */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                  </div>
                  {query.dealer ? (
                    <p className="ml-2 lg:ml-4 text-lg leading-6 text-gray-900">
                      Quickly submit and track all orders in real-time
                    </p>
                  ) : (
                    <p className="ml-2 lg:ml-4 text-lg leading-6 text-gray-900">
                      Real-time insights that fuel equipment uptime and reduce costs (available with
                      Pro + Enterprise plans)
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-8 mx-auto w-full max-w-md">
              <Form onSubmit={onSubmit}>
                <div className="bg-white py-4 px-4 sm:py-8 sm:px-10 shadow rounded-md sm:rounded-lg space-y-4">
                  {errorMsg && (
                    <div className="text-red-600 flex gap-1 items-center">
                      <ExclamationCircleIcon className="h-5 w-5" />
                      {errorMsg}
                    </div>
                  )}

                  <div>
                    <label className="block text-gray-700 text-base" htmlFor="user_email">
                      Email
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        value={form.email}
                        onChange={(e) => setForm({ ...form, email: e.target.value })}
                        className={styles.textInput}
                        id="user_email"
                        type="text"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-base" htmlFor="user_password">
                      Password
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        value={form.password}
                        onChange={(e) => setForm({ ...form, password: e.target.value })}
                        className={styles.textInput}
                        id="user_password"
                        type="password"
                      />
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <Action.Button primary disabled={submitting} type="submit">
                      {submitting ? <>Signing in&hellip;</> : 'Sign in'}
                    </Action.Button>
                    <a
                      className="font-medium text-gearflow hover:text-orange-700 focus:outline-none focus:underline transition ease-in-out duration-150 text-sm"
                      href="/reset-password"
                    >
                      Forgot your password?
                    </a>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </Layout>
  )
}

export default SignIn
