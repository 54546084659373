import { gql, useMutation } from '@apollo/client'

import { SignUpForm } from '../types'

interface Data {
  signUp: boolean
}

type Variables = SignUpForm

const mutation = gql`
  mutation SignUp(
    $membershipId: String!
    $companyName: String!
    $name: String!
    $email: String!
    $phoneNumber: String!
    $password: String!
    $passwordConfirmation: String!
    $emailOptIn: Boolean!
    $code: String!
  ) {
    signUp(
      membershipId: $membershipId
      companyName: $companyName
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      passwordConfirmation: $passwordConfirmation
      emailOptIn: $emailOptIn
      code: $code
    )
  }
`

const useSignUp = () => {
  const [mutate] = useMutation<Data, Variables>(mutation)

  return (variables: Variables) => mutate({ variables })
}

export default useSignUp
