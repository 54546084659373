import {
  CollectionIcon,
  MailIcon,
  LocationMarkerIcon,
  LockClosedIcon,
  ChartPieIcon,
  UserGroupIcon,
  ChevronDownIcon,
} from '@heroicons/react/outline'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { Disclosure } from '@headlessui/react'

import A from '@/gf/components/A'
import header from './ManufacturerSolutions/header.png'
import testimonal from './CASE_OEM_Fit_Machine_Control_588487.jpeg'
import support from './support.jpg'

import Layout from '../components/Layout'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const features = [
  {
    name: 'Catalog management',
    description:
      'Easily upload and update your entire catalog at list price across your distributor network.',
    icon: CollectionIcon,
  },
  {
    name: 'Dealer onboarding',
    description:
      'Add your dealers to expand your market penetration. If your dealer is already on Gearflow, simply opt in and grant them access to your catalog.',
    icon: MailIcon,
  },
  {
    name: 'Territory controls',
    description:
      'Make it so end customers can only purchase from dealers in their region. No territory restrictions? No problem! Let your customers decide who they want to work with.',
    icon: LocationMarkerIcon,
  },
  {
    name: 'Price protection',
    description:
      'Gearflow is designed to safeguard OEMs from a race to the bottom on price. We instituted controls around use of brand so you can be sure customers know they are buying quality.',
    icon: LockClosedIcon,
  },
  {
    name: 'Distributor performance',
    description:
      'Add visibility into your dealer sales and customer service so you can ensure all customers have a great experience with your brand.',
    icon: ChartPieIcon,
  },
  {
    name: 'Customer profiles',
    description:
      'Uncover market insights down to the account level so you know who your customers are and what kinds of fleets they have to empower your sales teams.',
    icon: UserGroupIcon,
  },
]

const ManufacturerSolutions = () => (
  <Layout>
    {/* Hero section */}
    <div className="relative">
      <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <img className="h-full w-full object-cover" src={header} alt="" />
            <div className="absolute inset-0 bg-gradient-to-r from-gearflow-dark to-gearflow-light mix-blend-multiply" />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-white">Grow your part sales</span>
              <span className="block text-white">through your dealers</span>
            </h1>
            <p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
              Sell more parts online to mixed fleet equipment owners without disrupting your current
              dealer or distributor workflows. You list the parts, and your dealers service the
              customers: Win-win.
            </p>
            <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
              <div className="flex justify-center">
                <a
                  href="https://landing-page.gearflow.com/request-demo-manufacturers"
                  className="px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-gearflow bg-white sm:px-8"
                >
                  Request a demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <div>
          <h2 className="text-base font-semibold tracking-wider text-gearflow uppercase">
            Own the customer lifecycle
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Serve mixed fleet customers
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            Establish the digital presence you need to more effectively work with today&apos;s owner
            of multiple equipment brands.
          </p>
        </div>
        <div className="h-32">&nbsp;</div>
        {/* <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
          <img
            className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
            src={placeholder}
            alt=""
          />
        </div> */}
      </div>
    </div>

    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-gearflow uppercase">
          Support dealers
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Differentiate your service offering
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          Arm your distributors with the product data and ecommerce tools they need to grow parts
          sales online without losing control of where your parts are sold.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-gearflow-dark to-gearflow-light rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

    <div className="pb-16 bg-gradient-to-r from-gearflow-dark to-gearflow-light lg:pb-0 lg:z-10 lg:relative">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
        <div className="relative lg:-my-8">
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
            <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
              <img className="object-cover lg:h-full lg:w-full" src={testimonal} alt="" />
            </div>
          </div>
        </div>
        <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
            <blockquote>
              <div>
                <svg
                  className="h-12 w-12 text-white opacity-25"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="mt-6 text-2xl font-medium text-white">
                  It is really important for us to think about the end customer and how we can help
                  them become more efficient when dealing with equipment repairs and speed up the
                  time to get them back to the job site. We are also very interested in ways that
                  simplify customers doing business with our dealers. Gearflow gives end users the
                  ability to do all that.
                </p>
              </div>
              <footer className="mt-6">
                <p className="text-base font-medium text-white">Jimmy Mansker</p>
                <p className="text-base font-medium text-cyan-100">
                  Global Director of Ecommerce at CNH Industrial
                </p>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>

    <section id="faqs" aria-labelledby="faq-heading" className="bg-white">
      <div className="max-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
            <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Frequently asked questions
            </h2>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              <Disclosure
                as="div"
                key="How can we help our dealers grow their parts sales?"
                className="pt-6"
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          How can we help our dealers grow their parts sales?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        It’s super simple! As the manufacturer, you have the best product data. By
                        uploading your entire parts catalog, it makes it easy for your dealers to
                        opt into what they want to sell, set their prices off list, and service
                        mixed fleet customers online. Bonus: You’ll not only get insight into their
                        performance, but also into the market as a whole.
                      </p>
                      <p className="mt-2 text-base text-gray-500">
                        All you need to do to get started is reach out to{' '}
                        <A href="mailto:suppliers@gearflow.com">suppliers@gearflow.com</A>.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" key="How do we get our dealers involved?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          How do we get our dealers involved?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        Once you’ve uploaded your parts catalog, you’ll be able to invite your
                        dealers to participate right from the platform. If you need to set territory
                        controls, you’ll also have the ability to do that as well.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure
                as="div"
                key="Do you handle training and onboarding of our dealers?"
                className="pt-6"
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          Do you handle training and onboarding of our dealers?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        Yes, we do! We’re all about making this as seamless as possible to help your
                        dealers succeed and make this a win-win-win situation for manufacturers,
                        dealers, and their end-user customers.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure
                as="div"
                key="How much does it cost us as the manufacturer and our dealers to participate?"
                className="pt-6"
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          How much does it cost us as the manufacturer and our dealers to
                          participate?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        As the manufacturer, it costs nothing for you to get started. Gearflow
                        charges a 12.65% fee on the order total for transactions where payment is
                        processed on our platform that is absorbed by the dealer.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" key="What product information do you need?" className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          What product information do you need?
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">
                        The more information you can provide about your products, the better, in
                        order to increase your chances that your products will be surfaced on Google
                        and in our marketplace. Detailed product descriptions, product images,
                        brands, categories, and alt MPNs also increase buyer confidence and
                        conversion rates
                      </p>
                      <p className="mt-2 text-base text-gray-500">
                        Please refer to our{' '}
                        <A href="https://www.notion.so/gearflow/Product-Best-Practices-Guide-252b8d311cec457586e68916d3754db8">
                          Product Best Practices Guide &nbsp;
                        </A>
                        for further details before sending us a CSV file of your products to{' '}
                        <A href="mailto:suppliers@gearflow.com">suppliers@gearflow.com</A>.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </dl>
          </div>
        </div>
      </div>
    </section>

    <div className="relative bg-gray-900">
      <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img className="w-full h-full object-cover" src={support} alt="" />
        <div aria-hidden="true" className="absolute inset-0 " />
      </div>
      <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
            Unparalleled Support.
          </h2>
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
            We&apos;re here to help
          </p>
          <p className="mt-3 text-lg text-gray-300">
            We understand your pains because we have lived them. Our tools are designed to enhance
            the relationships that make this industry go, not replace them.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="https://landing-page.gearflow.com/request-demo-manufacturers"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                Request a demo
                <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ManufacturerSolutions
