import React from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'
import { useQueryParams, StringParam } from 'use-query-params'
import { v4 as uuid } from 'uuid'
import URLSearchParams from '@ungap/url-search-params'

import { CreateRfqData, CreateRfqVars, CREATE_RFQ } from '@/gf/mutations/quotes'
import useSession from '../../hooks/useSession'
import useConfig from '@/gf/hooks/useConfig'
import useToggle from '@/gf/hooks/useToggle'

import TextInput from '@/gf/components/TextInput'
import TextArea from '@/gf/components/TextArea'
import Spinner from '@/gf/components/Spinner'

const uuidValue = `z${uuid()}`

const Modal = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const config = useConfig()
  const session = useSession()
  const [spinnerLive, spinner] = useToggle()

  const [query, _updateQuery] = useQueryParams({ gearflow_rfq: StringParam })
  const [open, toggler] = useToggle(query.gearflow_rfq !== undefined)
  const [createRfq] = useMutation<CreateRfqData, CreateRfqVars>(CREATE_RFQ)

  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phoneNumber, setPhoneNumber] = React.useState('')

  const [machineInformation, setMachineInformation] = React.useState('')
  const [partsRequest, setPartsRequest] = React.useState('')
  const [zipCode, setZipCode] = React.useState('')
  const [code, setCode] = React.useState<string>()

  React.useEffect(() => {
    if (session?.user) {
      if (name === '') setName(session.user.name || 'unknown')
      if (email === '') setEmail(session.user.email)
      if (phoneNumber === '') setPhoneNumber(session.user.phoneNumber || '')
    }
  }, [!session])

  const hideRfq = () => {
    const params = new URLSearchParams(location.search)
    params.delete('gearflow_rfq')
    navigate({ pathname: location.pathname, search: params.toString() })
  }

  const submitRfq = async (e) => {
    e.preventDefault()

    try {
      spinner.on()

      await createRfq({
        variables: {
          name,
          email,
          phoneNumber,
          machineInformation,
          partsRequest,
          zipCode,
          code,
        },
      })

      spinner.off()

      setName('')
      setEmail('')
      setZipCode('')
      setPhoneNumber('')
      setMachineInformation('')
      setPartsRequest('')

      const params = new URLSearchParams(location.search)
      params.delete('gearflow_rfq')
      params.append('rfq_complete', 'true')

      navigate({ pathname: location.pathname, search: params.toString() })
    } catch (error) {
      spinner.off()
    }
  }

  React.useEffect(() => {
    if (query.gearflow_rfq) {
      toggler.on()
    } else {
      toggler.off()
    }
  }, [query.gearflow_rfq])

  const adjustForm = (e) => {
    if (code !== '') {
      setCode(`${parseInt('0xFFC1074', 16)}`) // eslint-disable-line prefer-numeric-literals
    }
    setZipCode(e.target.value)
  }

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${open ? '' : 'hidden'}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />
        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        {spinnerLive ? (
          <Spinner />
        ) : (
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <form id={uuidValue} onSubmit={submitRfq}>
              <input name="_csrf_token" type="hidden" value={config.csrfToken} />
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  onClick={hideRfq}
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gearflow"
                >
                  <span className="sr-only">Close</span>
                  {/* Heroicon name: outline/x */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 text-gray-900" id="modal-title">
                    Request for quote
                  </h3>
                  <div className="mt-2">
                    <p className="pb-2 text-sm text-gray-500">
                      Ask a question and our team will do the heavy lifting!
                    </p>
                    <div className="mt-2 text-sm text-gray-700">
                      <div className="col-span-6 sm:col-span-2">
                        <div className="mt-1 rounded-md shadow-sm">
                          <TextInput
                            required
                            id="full_name"
                            name="full_name"
                            placeholder="Full Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <div className="mt-1 rounded-md shadow-sm">
                          <TextInput
                            required
                            pattern="^(1|)[2-9]\d{2}[2-9]\d{6}$"
                            type="tel"
                            id="phone_number"
                            name="phone_number"
                            placeholder="Phone"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <div className="mt-1 rounded-md shadow-sm">
                          <TextInput
                            required
                            pattern="^[^ ]+@[^ ]+\.[a-z]{2,6}$"
                            type="email"
                            id="email_address"
                            name="email_address"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <div className="mt-1 rounded-md shadow-sm">
                          <TextInput
                            required
                            id="zip_code"
                            name="zip_code"
                            placeholder="Zip Code"
                            value={zipCode}
                            onChange={adjustForm}
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <div className="mt-1 rounded-md shadow-sm">
                          <TextArea
                            required
                            id="machine_information"
                            name="machine_information"
                            placeholder="Machine information. Include information about the machine your parts request is for."
                            rows={3}
                            maxLength={200}
                            value={machineInformation}
                            onChange={(e) => setMachineInformation(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <div className="mt-1 rounded-md shadow-sm">
                          <TextArea
                            required
                            id="parts_request"
                            name="parts_request"
                            placeholder="Parts list. Include all relevant information to help with your request such as part numbers, dimensions, engine model, etc."
                            rows={4}
                            maxLength={200}
                            value={partsRequest}
                            onChange={(e) => setPartsRequest(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 sm:mt-2 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <input type="hidden" id="submit_for_rfq" name="submit_for_rfq" value="true" />
                <button
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gearflow text-base text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gearflow sm:col-start-1 sm:text-sm"
                  type="submit"
                >
                  Submit
                </button>
                <button
                  onClick={hideRfq}
                  type="button"
                  className="mt-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gearflow sm:mt-0 sm:col-start-2 sm:text-sm"
                >
                  Close
                </button>
              </div>
            </form>
            <p className="mt-4 flex items-center space-x-3">
              <span
                className="h-4 w-4 bg-green-100 rounded-full flex items-center justify-center"
                aria-hidden="true"
              >
                <span className="h-2 w-2 bg-green-400 rounded-full" />
              </span>
              <span className="text-sm text-gray-500">
                Gearflow only ships to Canada and the United States
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Modal
