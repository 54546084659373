/**
 * Card Component
 * -----------------
 * props
 * -----------------
 * title?: the header title of the card
 * section: wraps the children content in a Card.Section component
 * action?: action in the header
 */

import { CheckIcon, XIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { ReactNode } from 'react'

import { CardProps } from '../../types'

import Button from './Button'
import SpinnerSmall from './SpinnerSmall'

type CardSectionC = React.FC<{
  className?: string
  innerClassName?: string
  title?: ReactNode
  headerButton?: JSX.Element
  tooltip?: ReactNode
  children?: ReactNode
}>

const Section: CardSectionC = ({
  title,
  headerButton,
  tooltip,
  className,
  innerClassName,
  children,
}) => (
  <div className={`p-6 first:border-t-0 border-t-1 ${className || ''}`}>
    {(title || tooltip || headerButton) && (
      <div className="flex items-center justify-between pb-2">
        {(title || tooltip) && (
          <div className="flex flex-row items-center">
            {title && <h3 className="text-lg">{title}</h3>}
            {tooltip}
          </div>
        )}
        {headerButton}
      </div>
    )}
    <div className={innerClassName}>{children}</div>
  </div>
)

type CardSubSectionC = React.FC<{
  className?: string
  children?: ReactNode
}>

const SubSection: CardSubSectionC = ({ children, ...props }) => {
  const clazz = 'mt-6 pt-6 border-t-1 first:mt-0 first:pt-0 first:border-t-0'
  const clazzNames = props && props.className ? [clazz, props.className].join(' ') : clazz

  return <div className={clazzNames}>{children}</div>
}

const TitleContainer = ({
  title,
  subtitle,
}: {
  title: JSX.Element
  subtitle: JSX.Element | undefined
}) =>
  !subtitle ? (
    title
  ) : (
    <div>
      {title}
      {subtitle}
    </div>
  )

interface CardSubComponents {
  Section: CardSectionC
  SubSection: CardSubSectionC
}
type CardC = React.FC<CardProps> & CardSubComponents

const Card: CardC = ({
  title,
  subtitle,
  className,
  innerClassName,
  sectioned,
  headerButton,
  primaryFooterAction,
  secondaryFooterActions,
  children,
  tabs,
  currentTabName,
  onTabSelect,
  status,
  statusSpinnerClassName,
}) => (
  <div className={`bg-white shadow-sm border rounded-md ${className || ''}`}>
    {tabs && tabs.length > 0 && (
      <div className="border-b border-gray-200 pl-8 rounded-t-md">
        <ul className="-mb-px flex space-x-4" aria-label="Tabs">
          {tabs.map((tab, index) => {
            const current = currentTabName ? currentTabName === tab.name : index === 0
            return (
              <li key={tab.name} aria-current={current ? 'true' : undefined}>
                <button
                  className={classNames(
                    current
                      ? 'border-gearflow text-gray-700'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'group inline-flex items-center py-4 pb-3 px-3 border-b-2 font-medium text-sm leading-tight'
                  )}
                  type="button"
                  onClick={() => onTabSelect && onTabSelect(tab.name)}
                >
                  <div>
                    <span>{tab.name}</span>
                  </div>
                </button>
              </li>
            )
          })}
        </ul>
      </div>
    )}
    {(title || headerButton || status) && (
      <div className="w-full flex-wrap sm:flex-no-wrap gap-4 items-center justify-between pt-6 px-6 flex">
        {title && (
          <TitleContainer title={<h2 className="text-lg">{title}</h2>} subtitle={subtitle} />
        )}

        {(headerButton || status) && (
          <div className="flex flex-row items-center space-x-4">
            {status === 'success' ? (
              <div className="flex flex-row items-center space-x-0.5">
                <CheckIcon className="w-4 h-4 text-green-600" />
                <span className="text-xs text-gray-500">Saved</span>
              </div>
            ) : status === 'error' ? (
              <div className="flex flex-row items-center space-x-0.5">
                <XIcon className="w-4 h-4 text-red-600" />
                <span className="text-xs text-gray-500">Error</span>
              </div>
            ) : status === 'spinner' ? (
              <SpinnerSmall className={statusSpinnerClassName} />
            ) : null}
            {headerButton}
          </div>
        )}
      </div>
    )}
    <div className={innerClassName}>
      {sectioned ? <Section>{children}</Section> : <>{children}</>}
    </div>
    {(primaryFooterAction || secondaryFooterActions) && (
      <div className="px-6 pb-6 flex justify-end">
        {secondaryFooterActions &&
          secondaryFooterActions.map((action) => (
            <div key={action.id} className="mr-2">
              {'title' in action && 'callBack' in action ? (
                <Button
                  key={action.id}
                  title={action.title}
                  onClick={action.callBack}
                  primary={false}
                  className="mr-2"
                />
              ) : (
                action.element
              )}
            </div>
          ))}
        {primaryFooterAction &&
          ('title' in primaryFooterAction && 'callBack' in primaryFooterAction ? (
            <Button
              disabled={primaryFooterAction.disabled}
              performing={primaryFooterAction.showSpinner}
              title={primaryFooterAction.title}
              onClick={primaryFooterAction.callBack}
              primary
            />
          ) : (
            primaryFooterAction.element
          ))}
      </div>
    )}
  </div>
)

Card.Section = Section
Card.SubSection = SubSection
export default Card
