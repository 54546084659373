import React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

interface WithPrimary {
  primary?: boolean
}

type Size = 'sm' | 'md' | 'lg'

const classes = {
  base: 'block border font-normal rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50',
  primary: 'text-white border-transparent bg-gearflow hover:bg-yellow-700 focus:ring-yellow-500',
  nonPrimary: 'text-gray-700 border-gray-300 bg-white hover:bg-gray-50 focus:ring-yellow-500',
  sm: 'px-2.5 py-1.5 text-xs',
  md: 'px-4 py-2 text-sm',
  lg: 'px-6 py-3 text-base',
}

const genClassName = (className?: string, primary?: boolean, size?: Size) =>
  `${className} ${classes.base} ${primary ? classes.primary : classes.nonPrimary} ${
    classes[size || 'md']
  }`

// ==================================================

type AProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> &
  WithPrimary

const A = ({ children, className, primary, ...props }: AProps) => (
  <a className={genClassName(className, primary)} {...props}>
    {children}
  </a>
)

// ==================================================

const Link = ({ children, className, primary, ...props }: RouterLinkProps & WithPrimary) => (
  <RouterLink className={genClassName(className, primary)} {...props}>
    {children}
  </RouterLink>
)

// ==================================================

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  WithPrimary & { size?: Size }

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ type = 'button', children, className, primary, size = 'md', ...props }, ref) => (
    <button
      type={type} // eslint-disable-line react/button-has-type
      className={genClassName(className, primary, size)}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  )
)

export default { A, Link, Button }
