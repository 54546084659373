/**
 * Button Component
 * -----------------
 * just your regular old button
 *
 * props
 * -----------------
 * title - the title of the button
 * primary - boolean, true is orange, false is subdued (white)
 * type - defaults to button, but ocassionally need type submit for forms
 * className - extra classes
 * disabled - disabled button style (can still attach onClick handlers)
 */

import { ButtonHTMLAttributes, forwardRef } from 'react'
import Action from './ActionNext'

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color' | 'ref'> & {
  primary?: boolean
  performing?: boolean
}

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ primary = true, children, ...props }, ref) => {
    const Comp = primary ? Action.P : Action.S

    return (
      <Comp {...props} ref={ref}>
        {children || props.title}
      </Comp>
    )
  }
)

export default Button
