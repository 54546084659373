import { Routes, Route, Navigate } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import qs from 'query-string'

import ConfigProvider from '@/gf/providers/ConfigProvider'
import ErrorTrackerProvider from '@/gf/providers/ErrorTracker'
import SessionProvider from './providers/SessionProvider'
import StableNavigateProvider from '@/gf/providers/StableNavigateProvider'
import CategoriesProvider from './providers/CategoriesProvider'
import GqlClientProvider from './providers/GqlClientNext'
import LegacyGqlClientProvider from './providers/GqlClient'

import ErrorBoundary from '@/gf/components/ErrorBoundary'
import HashLinks from '@/gf/components/HashLinks'
import Home from './pages/Home'
import PartsHub from './pages/PartsHub'
import SalesHub from './pages/SalesHub'
import About from './pages/About'
import Category from './pages/Category'
import StoreProduct from './pages/StoreProduct'
import SourceProduct from './pages/SourceProduct'
import Search from './pages/Search'
import Brands from './pages/Brands'
import DealerInvite from './pages/DealerInvite'
import BuyerSolutions from './pages/BuyerSolutions'
import DealerSolutions from './pages/DealerSolutions'
import ManufacturerSolutions from './pages/ManufacturerSolutions'
import DistributorSolutions from './pages/DistributorSolutions'
import SignUp from './pages/SignUp'
import SignIn from './pages/SignIn'
import Remote from './pages/Remote'
import NotFound from './pages/NotFound'
import Pricing from './pages/Pricing'

const App = () => (
  <ConfigProvider>
    <ErrorTrackerProvider>
      <ErrorBoundary>
        <GqlClientProvider>
          <LegacyGqlClientProvider>
            <SessionProvider>
              <CategoriesProvider>
                <StableNavigateProvider>
                  <QueryParamProvider
                    adapter={ReactRouter6Adapter}
                    options={{
                      searchStringToObject: qs.parse,
                      objectToSearchString: qs.stringify,
                    }}
                  >
                    <HashLinks />
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/how-it-works" element={<Navigate to="parts-hub" replace />} />
                      <Route path="/parts-hub" element={<PartsHub />} />
                      <Route path="/sales-hub" element={<SalesHub />} />
                      <Route path="/pricing/*" element={<Pricing />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/c/*" element={<Category />} />
                      <Route path="/itm/*" element={<StoreProduct />} />
                      <Route path="/item/*" element={<SourceProduct />} />
                      <Route path="/search" element={<Search />} />
                      <Route path="/brands" element={<Brands />} />
                      <Route path="/dealer-invites/:id" element={<DealerInvite />} />
                      <Route path="/solutions/buyer" element={<BuyerSolutions />} />
                      <Route path="/solutions/dealer" element={<DealerSolutions />} />
                      <Route path="/solutions/manufacturer" element={<ManufacturerSolutions />} />
                      <Route path="/solutions/distributor" element={<DistributorSolutions />} />
                      <Route path="/signup" element={<SignUp />} />
                      <Route path="/login" element={<SignIn />} />
                      {[
                        'press',
                        'careers',
                        'terms-and-conditions',
                        'supplier-terms-and-conditions',
                        'privacy-policy',
                        'contact-us',
                      ].map((slug) => (
                        <Route path={`/${slug}`} element={<Remote />} key={slug} />
                      ))}
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </QueryParamProvider>
                </StableNavigateProvider>
              </CategoriesProvider>
            </SessionProvider>
          </LegacyGqlClientProvider>
        </GqlClientProvider>
      </ErrorBoundary>
    </ErrorTrackerProvider>
  </ConfigProvider>
)

export default App
