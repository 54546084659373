import classNames from 'classnames'

const Spinner = ({
  className,
  size = 'md',
}: {
  className?: string | undefined
  size?: 'sm' | 'md'
}) => (
  <div
    className={classNames(
      'animate-spin-slow border-t-gearflow rounded-full border-gray-200 mx-auto',
      size === 'sm' && 'border-4 border-t-4 h-8 w-8',
      size === 'md' && 'border-8 border-t-8 h-16 w-16',
      className
    )}
  />
)

export default Spinner
