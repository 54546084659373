import { useEffect, useRef, useState } from 'react'
import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import ProgressBar from './QuotingSteps/ProgressBar'
import step1 from './QuotingSteps/step_1.png'
import step2 from './QuotingSteps/step_2.png'
import step3 from './QuotingSteps/step_3.png'
import topBg from './QuotingSteps/top_bg.svg'
import bottomBg from './QuotingSteps/bottom_bg.svg'
import { motion, useScroll, AnimatePresence } from 'framer-motion'

const Section = ({ status, title, text, activeIndex, image }) => (
  <section className="flex flex-col justify-center sm:h-[70vh]">
    <div className="max-w-[29.125rem] flex-shrink-0 space-y-4 sm:space-y-6">
      <ProgressBar activeIndex={activeIndex} />
      <hgroup className="font-medium space-y-2">
        <h3 className="text-sm sm:text-lg">{status}</h3>
        <p className="text-2xl sm:text-3xl">{title}</p>
      </hgroup>
      <p className="text-base sm:text-lg">{text}</p>
    </div>
    <div className="w-screen sm:hidden flex-grow py-8 -mx-8">
      <img src={image} alt={title} />
    </div>
  </section>
)

const AnimatedImage = ({ visible, src }) => (
  <AnimatePresence>
    {visible && (
      <motion.img
        src={src}
        alt=""
        className="h-full w-full object-contain absolute"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      />
    )}
  </AnimatePresence>
)

const getImageFromScrollProgress = (value: number) => (value < 0.4 ? 1 : value < 0.65 ? 2 : 3)

const QuotingSteps = () => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const [visibleImage, setVisibleImage] = useState(1)

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    layoutEffect: true,
    offset: ['start end', 'end start'],
  })

  useEffect(() => {
    const initialScroll = scrollYProgress.get()
    setVisibleImage(getImageFromScrollProgress(initialScroll))

    scrollYProgress.on('change', (scrollProgress) =>
      setVisibleImage(getImageFromScrollProgress(scrollProgress))
    )
  }, [])

  return (
    <>
      <div className="bg-orange-50">
        <LayoutContainer
          className="hidden sm:block sm:pt-121 bg-no-repeat bg-orange-50"
          style={{
            backgroundImage: `url(${topBg})`,
            backgroundPosition: '-0.125rem center',
            backgroundSize: '157vw 63vw',
          }}
        />

        <LayoutContainer
          className="bg-no-repeat overflow-visible sm:-mt-121"
          style={{
            backgroundImage: `url(${bottomBg})`,
            backgroundPosition: 'center 101%',
            backgroundSize: '135vw',
          }}
        >
          <section>
            <div className="flex py-8 xl:py-16" ref={sectionRef}>
              <div className="flex flex-col w-full sm:w-1/2">
                <Section
                  status="Quote Submitted"
                  title="Fulfill parts requests from any customer"
                  text="Respond to customer's parts requests in minutes. Regardless of where that request came in, just upload your quote PDF to Gearflow."
                  activeIndex={0}
                  image={step1}
                />

                <Section
                  status="Track Orders"
                  title="Automate order tracking and problem reporting"
                  text="Enable your customers to track their order status in real time and report any problems with their order all without picking up the phone."
                  activeIndex={1}
                  image={step2}
                />

                <Section
                  status="Mobile Opt-In"
                  title="Empower repeat customers"
                  text="Close more repeat business by providing your customers with a free mobile ordering experience for future requests"
                  activeIndex={2}
                  image={step3}
                />
              </div>
              <div className="hidden sm:flex flex-col w-1/2 h-auto relative">
                <div
                  className="flex flex-col items-center w-full h-[70vh] sticky"
                  style={{ top: 'calc(50% - 35vh)' }}
                >
                  <AnimatedImage src={step1} visible={visibleImage === 1} />
                  <AnimatedImage src={step2} visible={visibleImage === 2} />
                  <AnimatedImage src={step3} visible={visibleImage === 3} />
                </div>
              </div>
            </div>
          </section>
        </LayoutContainer>
      </div>
    </>
  )
}

export default QuotingSteps
