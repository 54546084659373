import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useQueryParams, StringParam } from 'use-query-params'
import { CheckCircleIcon } from '@heroicons/react/outline'
import URLSearchParams from '@ungap/url-search-params'

import useToggle from '@/gf/hooks/useToggle'

const Complete = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [query, _updateQuery] = useQueryParams({ rfq_complete: StringParam })
  const [open, toggler] = useToggle(query.rfq_complete !== undefined)

  const hideModal = () => {
    const params = new URLSearchParams(location.search)
    params.delete('rfq_complete')
    navigate({ pathname: location.pathname, search: params.toString() })
  }

  React.useEffect(() => {
    if (query.rfq_complete) {
      toggler.on()
    } else {
      toggler.off()
    }
  }, [query.rfq_complete])

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${open ? '' : 'hidden'}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />
        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              onClick={hideModal}
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gearflow"
            >
              <span className="sr-only">Close</span>
              {/* Heroicon name: outline/x */}
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full">
              <CheckCircleIcon className="h-12 w-12 text-green-400" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <div className="border-t-1 border-gray-200">
                <h3 className="pt-4 text-center text-lg leading-6 text-gray-900" id="modal-title">
                  Submitted Successfully!
                </h3>
              </div>
              <div className="mt-8">
                <p className="pb-2 text-sm text-gray-500">
                  Thanks for requesting a quote. We will get back with you shortly!
                </p>

                <div className="mt-6 text-sm text-gray-700" />

                <div className="flex w-full text-center justify-center items-center">
                  <button
                    onClick={hideModal}
                    type="button"
                    className="w-full max-w-sm inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gearflow sm:mt-0 sm:col-start-2 sm:text-sm"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Complete
