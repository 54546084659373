import React from 'react'
import { useLocation } from 'react-router-dom'
import fetch from '@/gf/modules/fetch'
import Spinner from '@/gf/components/Spinner'
import Layout from '../components/LayoutNext'

const useFetchHtml = (path: string) => {
  const [html, setHtml] = React.useState<string>()

  React.useEffect(() => {
    fetch(path)
      .then((resp) => resp.text())
      .then(setHtml)
  }, [])

  return html
}

const Remote = () => {
  const location = useLocation()
  const html = useFetchHtml(`/html${location.pathname}`)

  return <Layout>{html ? <div dangerouslySetInnerHTML={{ __html: html }} /> : <Spinner />}</Layout>
}

export default Remote
