import React from 'react'
import { useLocation } from 'react-router-dom'
import useConfig from '@/gf/hooks/useConfig'
import fetch from '@/gf/modules/fetch'

const SubscribeToNewsletter = () => {
  const config = useConfig()
  const location = useLocation()
  const [email, setEmail] = React.useState('')
  const [msg, setMsg] = React.useState<string>()

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setMsg('')

    const data = {
      _csrf_token: config.csrfToken,
      email,
      'g-recaptcha-response': event.target['g-recaptcha-response'].value,
      query_params: location.search.replace('?', ''),
      redirect_to: location.pathname,
    }

    fetch('/email/subscribe', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }).then((resp) => {
      if (resp.status === 200) {
        setMsg('Thanks for subscribing!')
        setEmail('')
      } else {
        resp
          .json()
          .then((respData: { error?: string }) =>
            setMsg(respData.error || "We're sorry, something went wrong. Please try again.")
          )
      }
    })
  }

  return (
    <form onSubmit={onSubmit} className="mt-4">
      <div id="recaptcha" />
      <div className="sm:flex sm:max-w-md">
        <label htmlFor="email-address" className="sr-only">
          Email address
        </label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          name="email-address"
          id="email-address"
          autoComplete="email"
          required
          className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-5 text-gray-900 placeholder-gray-500 focus:outline-nonefocus:placeholder-gray-400"
          placeholder="Enter your email"
        />
        <div className="mt-2 rounded-md sm:mt-0 sm:ml-2 sm:flex-shrink-0">
          <button
            type="submit"
            className="w-full flex items-center justify-center bg-gradient-to-r bg-origin-border px-4 py-2 text-sm leading-5 font-medium rounded-md shadow-sm bg-white border border-gray-300 text-gray-700"
          >
            Subscribe
          </button>
        </div>
      </div>

      {msg && (
        <p className="mt-4 text-gray-600 text-base leading-6" role="alert">
          {msg}
        </p>
      )}
    </form>
  )
}

export default SubscribeToNewsletter
