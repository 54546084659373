import { DateTime } from 'luxon'
import { round } from 'mathjs'
import pluralize from 'pluralize'
import Duration from './Duration'

const dateTimeInputFormat = "yyyy-MM-dd'T'HH:mm"
const dateInputFormat = 'yyyy-MM-dd'

const now = () => DateTime.now().toLocal()

const fromString = (string: string) => DateTime.fromISO(string, { zone: 'utc' }).toLocal()
const fromPayload: {
  (payload: string): DateTime
  (payload: null): null
} = (payload: any): any => (typeof payload === 'string' ? fromString(payload) : null)
const fromFormat = (string: string, fmt: string) => DateTime.fromFormat(string, fmt).toLocal()
const fromFormatUtc = (string: string, fmt: string) =>
  DateTime.fromFormat(string, fmt, { zone: 'utc' }).toLocal()
const fromISO = (string: string) => DateTime.fromISO(string)
const fromDateTimeInputFormat = (string: string) => fromFormat(string, dateTimeInputFormat)
const fromDateInputFormat = (string: string) => fromFormat(string, dateInputFormat)

const toFormat = (dateTime: DateTime, fmt: string) => dateTime.toFormat(fmt)
const toString = (dateTime: DateTime) => dateTime.toLocaleString(DateTime.DATETIME_MED)
const toISO = (dateTime: DateTime) => dateTime.toISO()
const toPayloadNotNull = (dateTime: DateTime<true>) => dateTime.toUTC().toISO()
const toPayload = (dateTime: DateTime | null) => dateTime && toPayloadNotNull(dateTime)
const toDateTimeInputFormat = (dateTime: DateTime) => toFormat(dateTime, dateTimeInputFormat)
const toDateInputFormat = (dateTime: DateTime) => toFormat(dateTime, dateInputFormat)

const formatDateTime = (dateTime: DateTime) => dateTime.toLocaleString(DateTime.DATETIME_MED)
const formatDateTimeNoYear = (dateTime: DateTime) => dateTime.toLocal().toFormat('MMM d, t')
const formatDateNoTime = (dateTime: DateTime) => dateTime.toLocaleString(DateTime.DATE_MED)
const formatDateNoYear = (dateTime: DateTime) => dateTime.toLocal().toFormat('MMM d')
const formatSimpleDateTime = (dateTime: DateTime) => dateTime.toLocal().toFormat('D - h:mm a')
const formatDateWithWeekday = (dateTime: DateTime) =>
  dateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
const formatDateStringNoYear = (dateTime: DateTime) =>
  dateTime.toLocaleString({ month: 'short', day: '2-digit' })
const formatDateStringNoYearWithMinutes = (dateTime: DateTime) =>
  dateTime.toLocal().toFormat('MMM dd, h:mm a')

// Returns the date as a string formatted for the value of a date input field
const dateToInput = (date: Date) => date.toISOString().split('T')[0]

// Returns a string value of the given date string range
// This is used for chart x coordinate values
const toDateRangeString = (
  from: string,
  to: string,
  formatFunction: (dateTime: DateTime) => string = formatDateStringNoYear
) =>
  from === to
    ? formatFunction(fromString(from))
    : `${formatFunction(fromString(from))}-${formatFunction(fromString(to))}`

// Use this as the array sort function for dates with the most recent at the top
// ex: array.sort((a, b) => Time.sortDesc(a.datetime, b.datetime))
const sortDesc = (a: DateTime, b: DateTime) => (b < a ? -1 : b > a ? 1 : 0)

const localTimezone = () => DateTime.now().zoneName

// Convert number of hours into a string with [hours]:[minutes]
const readableHours = (hours: number): string => {
  const wholeHours = Math.trunc(hours)
  const wholeMinutes = String(Math.round((hours % 1) * 60)).padStart(2, '0')
  return `${wholeHours}:${wholeMinutes}`
}

// Get the interval of the given DateTime since now
const formatDiffNow = (dateTime: DateTime) => {
  const duration = dateTime.diffNow().negate()
  if (duration.shiftTo('hours').hours < 1) {
    return `${duration.toFormat('m')}m`
  }
  if (duration.shiftTo('days').days < 1) {
    return `${duration.toFormat('h')}h`
  }
  if (duration.shiftTo('weeks').weeks < 1) {
    return `${duration.toFormat('d')}d`
  }
  if (duration.shiftTo('years').years < 1) {
    return `${Math.floor(duration.shiftTo('weeks').weeks)}w`
  }
  return `${duration.toFormat('y')}y`
}

// Get the interval of the given DateTime since now
const formatDiffNowLong = (dateTime: DateTime) => {
  const duration = dateTime.diffNow().negate()
  if (duration.shiftTo('hours').hours < 1) {
    return `${duration.toFormat('m')} minutes`
  }
  if (duration.shiftTo('days').days < 1) {
    return `${duration.toFormat('h')} hours`
  }
  if (duration.shiftTo('weeks').weeks < 1) {
    return `${duration.toFormat('d')} days`
  }
  if (duration.shiftTo('years').years < 1) {
    return `${Math.floor(duration.shiftTo('weeks').weeks)} weeks`
  }
  return `${duration.toFormat('y')} years`
}

// Get the day as "Today" or "Yesterday" or another date format
const formatRelativeDay = (dateTime: DateTime, fallbackFormat = DateTime.DATE_SHORT) => {
  if (dateTime.startOf('day').equals(DateTime.now().startOf('day'))) return 'Today'
  if (dateTime.startOf('day').equals(DateTime.now().minus({ days: 1 }).startOf('day')))
    return 'Yesterday'
  if (dateTime.startOf('day').equals(DateTime.now().plus({ days: 1 }).startOf('day')))
    return 'Tomorrow'
  return dateTime.toLocaleString(fallbackFormat)
}

const formatDiffShort = (dateTime: DateTime, prevTime?: DateTime | null) => {
  const duration = (prevTime ? dateTime.diff(prevTime) : dateTime.diffNow()).negate()
  return Duration.formatShort(duration)
}

const formatDiff = (dateTime: DateTime, prevTime?: DateTime | null) => {
  const duration = (prevTime ? dateTime.diff(prevTime) : dateTime.diffNow()).negate()
  return Duration.format(duration)
}

const secondsToString = (seconds: number) => {
  const duration = Duration.fromSeconds(seconds)
  const days = round(duration.as('days'), 1)
  if (days >= 1) return `${days} ${pluralize('days', days)}`
  const hours = round(duration.as('hours'), 1)
  if (hours >= 1) return `${hours} ${pluralize('hrs', hours)}`
  const minutes = round(duration.as('minutes'))
  return `${minutes} ${pluralize('mins', minutes)}`
}

export default {
  now,
  fromString,
  fromPayload,
  fromFormat,
  fromISO,
  fromDateTimeInputFormat,
  fromDateInputFormat,
  toFormat,
  toString,
  toISO,
  toPayload,
  toPayloadNotNull,
  toDateTimeInputFormat,
  toDateInputFormat,
  formatDateTime,
  formatDateTimeNoYear,
  formatDateNoTime,
  formatDateNoYear,
  fromFormatUtc,
  formatSimpleDateTime,
  formatDateWithWeekday,
  formatDateStringNoYear,
  formatDateStringNoYearWithMinutes,
  dateToInput,
  toDateRangeString,
  sortDesc,
  localTimezone,
  readableHours,
  formatDiff,
  formatDiffShort,
  formatDiffNow,
  formatDiffNowLong,
  secondsToString,
  formatRelativeDay,
}
