import fetch from '@/gf/modules/fetch'
import useConfig from '@/gf/hooks/useConfig'

import { SignInForm } from '../types'

const useSignIn = () => {
  const config = useConfig()

  return (form: SignInForm) =>
    fetch('/sign_in', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': config.csrfToken,
      },
      body: JSON.stringify({ ...form, persistent_session: 'true' }),
    })
}

export default useSignIn
