import Layout from '../components/LayoutNext'
import IntroHero from './SalesHub/IntroHero'
import QuotingSteps from './SalesHub/QuotingSteps'
import BayCityTestimonial from './SalesHub/BayCityTestimonial'

import WhySalesHubPro from './SalesHub/WhySalesHubPro'
import ContactCallout from '../components/ContactCallout'
import SiteUrls from '../modules/SiteUrls'
import FAQ from '../components/FAQ'
import { SalesHubPricing } from '../components/PricingSection'

const SalesHub = () => (
  <Layout>
    <IntroHero />
    <WhySalesHubPro />
    <QuotingSteps />
    <BayCityTestimonial />
    <SalesHubPricing />

    <FAQ
      questions={[
        {
          question: 'How do we get started?',
          answer: [
            'Connect with us, and we’ll get you on your way!',
            'We know change can be hard, so we follow a 30+ day implementation plan to make sure your team is set up for success.',
          ],
        },

        {
          question: 'Do my customers need a Gearflow account?',
          answer: [
            'No account is necessary — your customers will receive your quote via email or text.',
            'It’s completely free for them to use Gearflow to submit parts request if they prefer a digital ordering experience.',
          ],
        },

        {
          question: 'What support do you offer?',
          answer: [
            'We have an expert team available to assist you from 8 a.m. to 5 p.m. CT Monday through Friday, except holidays.',
            'You can reach them on every request and order via Gearflow Messages or support@gearflow.com.',
          ],
        },
      ]}
    />
    <ContactCallout
      scheduleDemoUrl={SiteUrls.DEALER_SCHEDULE_DEMO_URL}
      text="Ready to empower your parts desk with the only software built to connect fleet teams and their vendors? We’ll guide you every step of the way."
    />
  </Layout>
)

export default SalesHub
