import React from 'react'
import useSession from '../../../hooks/useSession'
import UserMenuItems from './UserMenuItems'
import UserIcon from './UserIcon'
import Dropdown from '@/gf/components/next/Dropdown'
import { ChevronDownIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

const UserDropdown = () => {
  const session = useSession()

  if (!session) return null

  const { user } = session

  if (!user) return null

  return (
    <Dropdown
      trigger={
        <span className="hover:bg-slate-50 rounded-lg py-1 px-2 whitespace-nowrap hidden sm:flex items-center gap-2 text-base leading-6">
          <UserIcon /> {user.name}{' '}
          <ChevronDownIcon className="inline-block text-gray-700 h-4 w-4" />
        </span>
      }
      placement="bottom-end"
    >
      <div
        className={classNames(
          'rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden mt-2',
          user.store ? 'w-96' : 'w-48'
        )}
      >
        <div className="relative bg-white">
          <UserMenuItems />
        </div>
      </div>
    </Dropdown>
  )
}

export default UserDropdown
