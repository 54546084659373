/**
 * Modal Component
 * -----------------
 *
 * props
 * -----------------
 * open -- boolean value if modal is open or closed
 * setOpen -- funcion callback triggered open/closed state
 * title -- heading title of modal
 * body -- body text of modal
 * footerAction -- the action in the footer of the modal
 * withIcon -- boolean, if Icon should be shown alongside modal
 *
 */

import { Dialog, Portal, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { Fragment, useRef } from 'react'
import { ModalSize } from '../../types'
import Action from './ActionNext'

const Modal: React.FC<{
  open: boolean
  onClose: () => void
  title: React.ReactNode
  footerAction?: JSX.Element
  withIcon?: boolean
  cancelText?: string
  size?: ModalSize
  actionsText?: string
  children?: React.ReactNode
  showFooter?: boolean
  layer?: '1' | '2' | '3' | '4' | '5'
}> = ({
  open,
  onClose,
  title,
  footerAction,
  withIcon,
  children,
  actionsText,
  cancelText = 'Cancel',
  size = ModalSize.SM,
  showFooter = true,
  layer = '1',
}) => {
  const cancelButtonRef = useRef(null)

  return (
    <Portal>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className={classNames('fixed inset-0 overflow-y-auto', {
            'z-10': layer === '1',
            'z-20': layer === '2',
            'z-30': layer === '3',
            'z-40': layer === '4',
          })}
          initialFocus={cancelButtonRef}
          onClose={onClose}
        >
          <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle w-full',
                  {
                    'sm:max-w-lg': size === ModalSize.SM,
                    'sm:max-w-2xl': size === ModalSize.MD,
                    'sm:max-w-3xl': size === ModalSize.LG,
                    'sm:max-w-5xl': size === ModalSize.XL,
                  }
                )}
              >
                <div
                  className={classNames(
                    'bg-white rounded-t-lg px-4 pt-5 pb-4 sm:p-6 sm:pb-4',
                    !showFooter && 'rounded-b-lg'
                  )}
                >
                  {/* <div className="sm:flex sm:items-start"> */}
                  <div>
                    {withIcon && (
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                      </div>
                    )}
                    <div
                      className={
                        withIcon
                          ? 'mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full'
                          : 'mt-3 text-center sm:mt-0 sm:text-left sm:flex-1'
                      }
                    >
                      <Dialog.Title as="h3" className="text-lg leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                      <Action.T
                        type="button"
                        className="absolute top-3 right-3 rounded-full h-7 w-7 p-1 flex items-center justify-center hover:bg-slate-50 outline-none"
                        aria-label="Close"
                        onClick={onClose}
                      >
                        <XIcon className="text-slate-700" />
                      </Action.T>
                      <div className="mt-2">
                        <div className="text-sm text-gray-500">{children}</div>
                      </div>
                    </div>
                  </div>
                </div>
                {showFooter && (
                  <div className="bg-gray-50 rounded-b-lg px-4 py-3 sm:px-6 flex justify-end items-center gap-2">
                    {actionsText && <div className="text-sm text-gray-500">{actionsText}</div>}

                    <Action.S onClick={() => onClose()} ref={cancelButtonRef}>
                      {cancelText}
                    </Action.S>

                    {footerAction}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Portal>
  )
}

export default Modal
