// DEPRECATED

import { forwardRef } from 'react'

import A from './ANext'

import type { ButtonProps } from './ANext'

const ButtonLink = forwardRef<HTMLAnchorElement, ButtonProps & { primary?: boolean }>(
  ({ primary = true, ...props }, ref) => {
    const C = primary ? A.P : A.S
    return <C ref={ref} {...props} />
  }
)

export default ButtonLink
