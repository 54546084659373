import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import topBg from './BookDemoCalloutBottom/top_bg.svg'
import SiteUrls from '@/retail/modules/SiteUrls'
import LinkButton from '../../components/LayoutNext/LinkButton'

const BookDemoCallOutBottom = () => (
  <LayoutContainer
    className="bg-orange-50 bg-no-repeat"
    style={{
      backgroundImage: `url(${topBg})`,
      backgroundPosition: 'center -2vh',
      backgroundSize: '100vw',
    }}
  >
    <section className="pt-12 pb-12 sm:pt-[7.43rem] sm:pb-24 flex flex-col sm:flex-row sm:items-center gap-x-8 gap-y-8">
      <p className="font-medium text-2xl sm:text-5xl sm:leading-[3.25rem] text-center sm:text-left flex-grow">
        Ready to turn your parts process into a competitive advantage?
      </p>
      <div className="gap-x-4 flex justify-center sm:justify-end flex-shrink-0">
        <LinkButton href={SiteUrls.BUYER_SCHEDULE_DEMO_URL} target="_blank">
          Book a Demo
        </LinkButton>
        <LinkButton href="/how-it-works" variation="secondary">
          Learn More
        </LinkButton>
      </div>
    </section>
  </LayoutContainer>
)

export default BookDemoCallOutBottom
