import URLSearchParams from '@ungap/url-search-params'
import { useNavigate, useLocation } from 'react-router-dom'
import { XIcon } from '@heroicons/react/outline'

import useConfig from '@/gf/hooks/useConfig'
import useSession from '../../hooks/useSession'

import Action from '@/gf/components/ActionV2'
import ButtonLink from '@/gf/components/ButtonLink'

const RFQBanner = ({ onClose }: { onClose?: () => void }) => {
  const config = useConfig()
  const session = useSession()
  const navigate = useNavigate()
  const location = useLocation()

  const showRfqModal = () => {
    const params = new URLSearchParams(location.search)
    params.append('gearflow_rfq', 'true')
    navigate({ pathname: location.pathname, search: params.toString() })
  }

  return (
    <div className="bg-gray-50">
      <div className="flex items-start justify-between space-x-3 mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 py-4">
        <div className="space-y-2">
          <h2 className="text-lg font-bold text-gray-900" id="slide-over-title">
            Source Your Parts Instantly
          </h2>
          <p className="prose">
            Stop shopping and simply send us the part you&apos;re looking for via our automated RFQs
            broadcast to the largest network of suppliers.
          </p>
          <div className="flex gap-8 items-center">
            {session?.user ? (
              <ButtonLink href={`${config.buyersUrl}/rfqs/create`}>Request Quote</ButtonLink>
            ) : (
              <Action.Button onClick={showRfqModal} primary>
                Request Quote
              </Action.Button>
            )}
            <p className="prose text-sm">
              Gearflow is more than a marketplace &mdash; we are your parts sourcing solution.
            </p>
          </div>
        </div>
        {onClose && (
          <div className="flex h-7 items-center">
            <button type="button" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Close panel</span>
              <XIcon onClick={onClose} className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default RFQBanner
