import React from 'react'
import SpinnerSmall from './SpinnerSmall'

type Size = 'sm' | 'md' | 'lg'

type LinkProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  primary?: boolean
  size?: Size
}

const linkClasses = {
  base: 'block text-blue-500 underline',
  primary: 'font-bold',
  nonPrimary: '',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
}

const Link = React.forwardRef<HTMLButtonElement, LinkProps>(
  ({ primary, children, type = 'button', size = 'md', ...props }, ref) => {
    const className = [
      linkClasses.base,
      primary ? linkClasses.primary : linkClasses.nonPrimary,
      linkClasses[size],
    ].join(' ')

    return (
      <button
        className={className}
        type={type} // eslint-disable-line react/button-has-type
        ref={ref}
        {...props}
      >
        {children}
      </button>
    )
  }
)

// ========================================

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  primary?: boolean
  size?: Size
  showSpinner?: boolean
}

const buttonClasses = {
  base: 'block border font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50',
  primary: 'text-white border-transparent bg-gearflow hover:bg-yellow-700 focus:ring-yellow-500',
  nonPrimary: 'text-gray-700 border-gray-300 bg-white hover:bg-gray-50 focus:ring-yellow-500',
  sm: 'px-2.5 py-1.5 text-xs',
  md: 'px-4 py-2 text-sm',
  lg: 'px-6 py-3 text-base',
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { primary, children, type = 'button', size = 'md', disabled, showSpinner = false, ...props },
    ref
  ) => {
    const className =
      disabled || showSpinner
        ? [
            buttonClasses.base,
            'flex text-gray-400 border-gray-300 bg-gray-200 cursor-not-allowed',
            buttonClasses[size],
          ].join(' ')
        : [
            buttonClasses.base,
            primary ? buttonClasses.primary : buttonClasses.nonPrimary,
            buttonClasses[size],
          ].join(' ')

    return (
      <button
        disabled={disabled}
        type={type} // eslint-disable-line react/button-has-type
        className={className}
        ref={ref}
        {...props}
      >
        {showSpinner ? <SpinnerSmall /> : children}
      </button>
    )
  }
)

export default { Button, Link }
