import React from 'react'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import useFetchDealerInvite from '../../hooks/useFetchDealerInvite'
import useAcceptDealerInvite from '../../hooks/useAcceptDealerInvite'
import AcceptDealerInviteFormM from '../../modules/AcceptDealerInviteForm'
import useToggle from '@/gf/hooks/useToggle'

import useConfig from '@/gf/hooks/useConfig'
import SpinnerSmall from '@/gf/components/SpinnerSmall'
import Card from '@/gf/components/Card'
import TextInput from '@/gf/components/TextInput'
import Action from '@/gf/components/Action'

import {
  AcceptDealerInviteForm,
  AcceptDealerInviteFormField,
  AcceptDealerInviteFormErrors,
} from '../../types'

const MaybeError = ({ error }: { error?: string }) => {
  if (!error) return null

  return <div className="flex gap-2 text-sm text-red-500 px-4 py-2 bg-gray-100">{error}</div>
}

const AcceptForm = () => {
  const { id } = useParams<{ id: string }>() as { id: string }
  const config = useConfig()
  const variables = { id }
  const fetchDealerInviteResult = useFetchDealerInvite({ variables })
  const dealerInvite = fetchDealerInviteResult.data?.dealerInvite

  const accept = useAcceptDealerInvite()
  const [errors, setErrors] = React.useState<AcceptDealerInviteFormErrors>({})
  const [submitting, submittingToggler] = useToggle(false)

  const [form, setForm] = React.useState<AcceptDealerInviteForm>({
    dealerInviteId: id,
    name: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    password: '',
    passwordConfirmation: '',
  })

  React.useEffect(() => {
    if (dealerInvite) setForm({ ...form, email: dealerInvite.email })
  }, [!dealerInvite])

  if (dealerInvite === undefined) return null

  const onSubmit = (e, complete) => {
    if (complete) {
      e?.preventDefault()
    }

    submittingToggler.on()

    const cleanedForm = AcceptDealerInviteFormM.clean(form)

    const newErrors = dealerInvite.storeId ? {} : AcceptDealerInviteFormM.validate(cleanedForm)

    if (complete && isEmpty(newErrors)) {
      accept(cleanedForm)
        .then(() => {
          e.target.submit(e, false)
        })
        .catch((err) => {
          setErrors(err.graphQLErrors[0])
        })
    } else {
      setErrors(newErrors)
      submittingToggler.off()
    }
  }

  const field = (formField: AcceptDealerInviteFormField) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrors({})
      setForm({ ...form, [formField]: event.target.value })
    }

    return { value: form[formField], onChange }
  }

  return (
    <Card>
      <Card.Section>
        {dealerInvite.acceptedAt ? (
          <div className="space-y-4">
            <h3 className="text-xl">Thanks for signing up!</h3>
            <div>
              <a
                href="/login"
                className="text-body text-gearflow hover:text-orange-700 focus:outline-none focus:underline transition ease-in-out duration-150 text-lg"
              >
                Sign in now
              </a>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <h3 className="text-xl">New Dealer Sign-up</h3>
            <form
              onSubmit={(e) => onSubmit(e, true)}
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              id="signup_form"
              action="/login"
              method="post"
              spellCheck="false"
              className="space-y-4"
            >
              {dealerInvite.storeId ? (
                <>
                  <Action.Button type="submit">
                    {submitting ? <SpinnerSmall /> : <>Accept Invite</>}
                  </Action.Button>
                </>
              ) : (
                <>
                  <TextInput placeholder="Company Name" required {...field('companyName')} />
                  <TextInput placeholder="Your Name" required {...field('name')} />
                  <div>
                    <TextInput placeholder="Email" required {...field('email')} />
                    <MaybeError error={errors.email} />
                  </div>
                  <div>
                    <TextInput placeholder="Phone Number" required {...field('phoneNumber')} />
                    <MaybeError error={errors.phoneNumber} />
                  </div>
                  <div>
                    <TextInput
                      placeholder="Password"
                      required
                      {...field('password')}
                      type="password"
                    />
                    <MaybeError error={errors.password} />
                  </div>
                  <div>
                    <TextInput
                      placeholder="Password, again"
                      required
                      {...field('passwordConfirmation')}
                      type="password"
                    />
                    <MaybeError error={errors.passwordConfirmation} />
                  </div>
                  <Action.Button type="submit">
                    {submitting ? <SpinnerSmall /> : <>Sign Up</>}
                  </Action.Button>

                  <div className="flex flex-col md:w-full">
                    <div className="flex items-center -mt-1 md:w-full">
                      <label
                        htmlFor="form_sms_agreement"
                        className="text-xs font-medium leading-5 text-gray-600"
                      >
                        By signing up, you authorize Gearflow to send communications (including text
                        messages if applicable) with offers & other information, possibly using
                        automated technology. Consent is not a condition of purchase. Message/data
                        rates apply.
                      </label>
                    </div>
                  </div>

                  <input name="_csrf_token" type="hidden" value={config.csrfToken} />
                  <input name="_method" type="hidden" value="put" />
                  <input name="user[email]" type="hidden" id="user_email" value={form.email} />
                  <input
                    name="user[password]"
                    id="user_password"
                    type="hidden"
                    value={form.password}
                  />
                </>
              )}
            </form>
          </div>
        )}
      </Card.Section>
    </Card>
  )
}

export default AcceptForm
