import ContactCallout from '@/retail/components/ContactCallout'
import BayCityTestimonial from '../SalesHub/BayCityTestimonial'

import SiteUrls from '@/retail/modules/SiteUrls'
import { SalesHubPricing } from '@/retail/components/PricingSection'
import Navigation from './Navigation'

const SalesHub = () => (
  <>
    <SalesHubPricing
      title="Pricing"
      navigation={<Navigation selectedLink="sales-hub" />}
      showLearnMoreLink
    />

    <BayCityTestimonial />

    <ContactCallout
      scheduleDemoUrl={SiteUrls.DEALER_SCHEDULE_DEMO_URL}
      text="Ready to empower your parts desk with the only software built to connect fleet teams and their vendors? We’ll guide you every step of the way."
    />
  </>
)

export default SalesHub
